const Photo = ({ desc, img }) => (
  <div
    style={{
      border: "1px solid white",
      borderRadius: "5px",
      padding: "10px",
      margin: "10px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "black",
    }}
  >
    <img alt={desc} src={img} width={"340px"} />
  </div>
);

export default Photo;
