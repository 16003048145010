import { useState } from "react";
import {
  ThemeProvider,
  createTheme,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
} from "@mui/material";
import {
  Twitter,
  Call,
  Create,
  Article,
  Description,
} from "@mui/icons-material";
import {
  Header,
  Action,
  Form,
  Photo,
  PreviewModal,
  Section,
} from "./components";
import imgArr from "./assets";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const links = [
  {
    link: "https://www.thebaltimorebanner.com/community/public-health/darren-klugman-johns-hopkins-UIYEXTJASFBVNI7MJSYLPYUENE/",
    text: "Baltimore Banner",
  },
  {
    link: "https://www.thedailybeast.com/johns-hopkins-dr-darren-klugman-placed-on-leave-after-racist-posts-about-palestinians",
    text: "Daily Beast",
  },
  {
    link: "https://twitter.com/StopZionistHate/status/1725012836227629132",
    text: "Video Coverage",
  },
];

const moreAction = [
  {
    link: "tel:+14105029430",
    text: "Call Johns Hopkins Medicine VP of Communications at 410-502-9430",
    icon: <Call />,
    hasChildren: true,
  },
  {
    link: "https://tinyurl.com/2s5we29v",
    text: "Sign the petition",
    icon: <Create />,
  },
  {
    link: "https://twitter.com/intent/tweet?text=@HopkinsMedicine",
    text: "Tweet @HopkinsMedicine",
    icon: <Twitter />,
  },
  {
    link: "https://t.co/Y2rc6E4LaH",
    text: "Report to MD state medical board",
    icon: <Article />,
  },
];

const App = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [isPhoneScript, setIsPhoneScript] = useState(false);

  const openDialog = (title, isPhoneScript) => {
    setDialogTitle(title);
    setIsPhoneScript(isPhoneScript);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <Header />
        <Section title={"Background"}>
          Dr. Darren Klugman is the director of the Pediatric Cardiac Intensive
          Care Unit at the Johns Hopkins Hospital, where he has full power over
          medical care for some of the sickest and most vulnerable children in
          our society.
          <br />
          <br />
          He is also a racist who has called for the mass slaughter and
          expulsion of all Palestinians, calling them “morally depraved,”
          “barbaric,” and “savage animals.”
          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {imgArr.map(({ img, desc }) => (
              <Photo key={desc} desc={desc} img={img} />
            ))}
          </div>
        </Section>
        <Section title={"Call to Action"}>
          Despite public outcry, Dr. Klugman continues to be employed at Johns
          Hopkins, and is on paid leave as of Thursday, Nov. 16th. Johns Hopkins
          leadership has yet to name the physician or take decisive action
          against him despite the vitriol espoused in Dr. Klugman's statements.
          <br />
          <br />
          Send an email (
          <span
            className="dialog-link"
            onClick={() =>
              openDialog(
                "Email to Johns Hopkins Medicine & the Maryland Board of Physicians",
                false
              )
            }
          >
            read the sample here
          </span>
          ) to Johns Hopkins leadership and the Maryland Board of Physicians
          through the form below to let them know this is utterly unacceptable.
          Let them know that patients will not feel safe at Hopkins until Dr.
          Klugman is removed from his position and his medical license is
          revoked.
          <br />
          <br />
          <Form />
          <br />
        </Section>
        <Section title={"More Actions to Take"}>
          <List>
            {moreAction.map(({ link, text, icon, hasChildren }) => (
              <>
                <Action key={text} icon={icon} link={link} text={text}>
                  {hasChildren && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 8 }}
                        onClick={() =>
                          openDialog("Script for Phone Call", true)
                        }
                      >
                        <ListItemIcon>
                          <Description />
                        </ListItemIcon>
                        <ListItemText primary={"Sample Script"} />
                      </ListItemButton>
                    </List>
                  )}
                </Action>
                <Divider
                  variant="fullWidth"
                  sx={{ borderColor: "rgba(255, 255, 255, 1)" }}
                />
              </>
            ))}
          </List>
        </Section>
        <Section title={"Further Reading"}>
          <List>
            {links.map(({ link, text }) => (
              <Action key={link} link={link} text={text} />
            ))}
          </List>
        </Section>
        <PreviewModal
          handleClose={closeDialog}
          handleOpen={openDialog}
          open={dialogOpen}
          title={dialogTitle}
          isScript={isPhoneScript}
        />
      </ThemeProvider>
    </div>
  );
};

export default App;
