import img1 from "./DK morally depraved animals.png";
import img2 from "./DK savage-animals.png";
import img3 from "./DK God Willing.png";
import img4 from "./DK tweet middle finger copy.jpg";
import img5 from "./DK move israelis back in.jpeg";
import img6 from "./DK tweet all palestinians.jpeg";

const imgArr = [
  {
    img: img1,
    desc: "Dr Klugman calling the Palestinians blood-thirsty morally depraved animals ",
  },
  {
    img: img2,
    desc: "Dr Klugman calling Palestinians savage animals",
  },
  {
    img: img3,
    desc: "2021 tweet where Dr Klugman said the palestinians refuse & invite death",
  },
  {
    img: img4,
    desc: "Dr. Klugman giving the middle finger three times in response to the UN Secretary-General calling for humanitarian assistance to Palestinian civilians",
  },
  {
    img: img5,
    desc: "Dr Klugman: I would move Israelis back in and re-establish Israeli farms. Then talk cease fire",
  },
  {
    img: img6,
    desc: "Dr Klugman saying it's not Hamas, but ALL Palestinians who've bitten off more than they can chew",
  },
];

export default imgArr;
