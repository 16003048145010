import { Typography } from "@mui/material";

const Section = ({ title, children }) => (
  <section>
    <Typography variant="h2" sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
      {title}
    </Typography>
    <br />
    <Typography variant="body1">{children}</Typography>
    <br />
    <br />
  </section>
);

export default Section;
