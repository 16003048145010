import { Typography } from "@mui/material";
import dk from "../assets/DK photo.jpg";

const Header = () => (
  <header>
    <Typography variant="h1" sx={{ fontSize: "2rem", fontWeight: "bold" }}>
      Email Johns Hopkins and Maryland Board to Terminate Dr. Darren Klugman
    </Typography>
    <br />
    <Typography variant="body1">
      Demand Dr. Darren Klugman's immediate termination from Johns Hopkins
      Medicine and revocation of his medical license from the Maryland Board of
      Physicians.
    </Typography>
    <br />
    <img
      src={dk}
      alt="Dr Klugman"
      style={{ display: "block", margin: "0 auto", width: "250px" }}
    />
    <br />
    <hr />
    <br />
  </header>
);

export default Header;
