import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Circle } from "@mui/icons-material";

const Action = ({ icon, link, text, children }) => (
  <>
    <ListItemButton component="a" target="_blank" rel="noreferrer" href={link}>
      <ListItemIcon>
        {icon ? icon : <Circle sx={{ width: 10, height: 10 }} />}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
    {children}
  </>
);

export default Action;
