import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const PreviewModal = ({ title, isScript, handleClose, open }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        style={{ position: "absolute", top: 0, right: 10 }}
      >
        <Close />
      </IconButton>
      <Typography variant="h5">{title}</Typography>
    </DialogTitle>
    {isScript ? (
      <DialogContent>
        <Typography variant="body1">
          As a [JHM patient/concerned community member], I am very upset that
          Dr. Darren Klugman has not been fired despite his very racist views.
          Patients do not feel safe coming to Hopkins and he cannot be trusted
          to be impartial and equitable in his care of patients.”
        </Typography>
      </DialogContent>
    ) : (
      <DialogContent>
        <Typography variant="h6">
          Subject: Call for the termination of Dr. Darren Klugman
        </Typography>
        <br />

        <Typography variant="body2">
          To Johns Hopkins Medicine Leadership and the Maryland Board of
          Physicians,
        </Typography>
        <br />
        <Typography variant="body2">
          As you know, Dr. Klugman has publicly called for the mass slaughter
          and expulsion of all Palestinians, calling them “morally depraved,”
          “barbaric,” and “savage animals.” This was not a momentary lapse of
          judgment. His recent tweets span several days, and he has tweeted
          similar sentiments as far back as 2021 (see screenshots at{" "}
          <a
            style={{ color: "#ffadff" }}
            href="https://tinyurl.com/49n64tay"
            target="_blank"
            rel="noreferrer"
          >
            https://tinyurl.com/49n64tay)
          </a>
          . Furthermore, Dr. Klugman's apology email demonstrates his continued
          disregard for the overwhelming loss of life in Gaza, and the enormous
          harm that he has done to so many patients who can no longer feel safe
          with the medical professionals and institutions permitting such
          vitriolic hatred from its leaders.
        </Typography>
        <br />
        <Typography variant="body2">
          Despite the abhorrent and dangerous nature of Dr. Klugman’s views and
          statements, JHM has minimized them as merely "divisive" and only
          "perceived as violent, racist and inappropriate." MBP has refused to
          even disclose if Dr. Klugman is under investigation. During this time,
          Dr. Klugman continues to be employed by JHM and is currently enjoying
          a paid leave. It is absurd that a physician who has vowed to first and
          foremost do no harm would respond to the “large scale slaughter” of
          Palestinians with “G-d willing,” and it is outrageous that JHM and MBP
          continue to protect and defend him.
        </Typography>
        <br />
        <Typography variant="body2">
          I demand that JHM leadership:
          <ol>
            <li>
              immediately terminate Dr. Klugman and recommend his medical
              license be revoked by the Maryland Board of Physicians.
            </li>
            <li>
              issue an unequivocal acknowledgement and condemnation of Krugman's
              genocidal, violent, racist views, as well as an apology for
              previously minimizing the severity of his conduct.
            </li>
            <li>
              meet with Palestinian, Arab and Muslim faculty, students and
              patients to address the serious concerns for their safety and
              well-being.
            </li>
            <li>
              outline a concrete plan for improving JHM's handling of such
              racism within its ranks.
            </li>
          </ol>
        </Typography>
        <br />
        <Typography variant="body2">
          I demand that MBP:
          <ol>
            <li>immediately revoke Dr. Klugman’s medical license. </li>
            <li>
              issue an unequivocal acknowledgement and condemnation of Krugman's
              genocidal, violent, racist views.
            </li>
          </ol>
        </Typography>
        <br />
        <Typography variant="body2">
          Johns Hopkins Medicine is a hallmark of excellence in modern medicine,
          and Dr. Klugman is a stain on your reputation. The Maryland Board of
          Physicians exists to uphold the Code of Medical Ethics, which Dr.
          Klugman is in clear violation of. Ultimately, it is incumbent on both
          of your institutions to protect patients and I am confident that you
          will take the appropriate steps to ensure that this is dealt with
          clearly and decisively.
        </Typography>

        <br />
        <Typography variant="body2">Regards,</Typography>
        <Typography variant="body2">Your Name</Typography>
      </DialogContent>
    )}
  </Dialog>
);

export default PreviewModal;
